* {
    padding: 0;
    margin: 0;
}

.container{
    max-width: 1224px;
    width: 90%;
    margin: auto;
    padding: 40px 0;
}

.title{
    margin-bottom:  2rem;
}

.photo-gallery{
    display: flex;
    gap: 20px;

}

.column {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.photo img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}
